import SectionHero from "components/SectionHero/SectionHero";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionDownloadApp from "./SectionDowloadApp";
import SectionSliderNewCategories from "./SectionSliderNewCategories";
import SectionManagedAds from "./SectionManagedAds";
import { Helmet } from 'react-helmet';


 
function PageHome() {


  return (
    <div>
      <Helmet>
      <link rel="canonical" href="https://feelaxo.com/" /> 
        <title>Schedule Appointments with Top-Rated Salons and Spa Near You</title>
          <meta name="keywords" content="Schedule appointments, salons, spa, wellness centers, Mumbai, Thane, Navi Mumbai, India, schedule online, appointments, Free Salon Management Software, Salon Software for small business, SAP software, Salon POS in India, Salon CRM, add in every page keywords" />
          <meta name="description" content="Feelaxo - Schedule SPA and Salon services online in India, Find best SPA and Salon near me, Free Salon Management Software, Boost Your Business Revenue With Our POS & CRM Software" />
      </Helmet>
      
        <div className="nc-PageHome relative overflow-hidden">
        <BgGlassmorphism />
        <SectionHero className="pt-5 lg:pt-16 lg:pb-16" /> 
        <div className="lg:ml-20 lg:mr-20 ml-8 mr-8 relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
          <div className="relative container py-16">
            <SectionGridFeaturePlaces />
            {/* Managed ads */}
            {/* <SectionManagedAds/> */}
            <SectionSliderNewCategories /> 
            {/* <SectionDownloadApp /> */}
          </div>
        </div> 
      </div>
    </div>
  );
}

export default PageHome;