import { FC, useState, useEffect } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import Startrating from "components/StartRating/StartRating";
import { useNavigate } from 'react-router-dom';
import { Amenity, BusinessData } from "../../types/types";

export interface StayCardHProps {
  className?: string;
  data?: {
    galleryImgs: string[];
    address: string;
    opening: string;
    title: string;
    id: string | number;
    description: string;
    service_name?: string;
    service_price?: string;
    service_duration?: string;
    service_for?: string;
    rating: string;
    total_rating: string;
    amenities?: Amenity[];
    business_type: number;
    claimed: number;
  };
  city?: string;
}

const StayCardH: FC<StayCardHProps> = ({ className = "", data, city }) => {

  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.matchMedia("(max-width: 768px)").matches);
    };
    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  if (!data) {
    return null;
  }

  const makeSEOUrl = (businessName: string, businessId: string | number, businessType: number) => {
    let url = `/venue/`;
    let bType: string;

    if (businessType === 1) {
        bType = "spa";
    } else if (businessType === 2) {
        bType = "salon";
    } else if (businessType === 3) {
        bType = "wellness";
    } else {
        throw new Error("Invalid business type");
    }

    const sanitizedCityName = city?.replace(/\s/g, "-");
    const sanitizedBusinessName = businessName.replace(/\s/g, "-");

    url = `${url}${bType}/${sanitizedCityName}/${sanitizedBusinessName}?id=${businessId}`;
    navigate(url);
  }

  const makeSEOUrlReturn = (businessName: string, businessId: string | number, businessType: number) => {
    let url = `/venue/`;
    let bType: string;

    if (businessType === 1) {
        bType = "spa";
    } else if (businessType === 2) {
        bType = "salon";
    } else if (businessType === 3) {
        bType = "wellness";
    } else {
        throw new Error("Invalid business type");
    }

    const sanitizedCityName = city?.replace(/\s/g, "-");
    const sanitizedBusinessName = businessName.replace(/\s/g, "-");

    url = `${url}${bType}/${sanitizedCityName}/${sanitizedBusinessName}?id=${businessId}`;
    return url;
  }

  const { galleryImgs, address, title, id, opening, business_type, description } = data;

  const renderSliderGallery = () => {
    return (
      <div className="relative flex-shrink-0 w-full md:w-72">
        <GallerySlider
          ratioClass="aspect-w-6 aspect-h-5"
          galleryImgs={galleryImgs}
          uniqueID={`StayCardH_${id}`}
          href={makeSEOUrlReturn(title, id, business_type)}
          business_type={business_type}
          business_id={id}
          opening={opening}
          business_name={data.title}
          business_address={data.address}
          business_city={city || ""}
        />
      </div>
    );
  };

  const renderAmenities = () => {
    if (!data.amenities || data.amenities.length === 0) {
      return null;
    }

    return (
      <>
      {/* <div className="grid grid-cols-3 gap-2 p-2">
        {data.amenities.map((amenity, index) => (
          amenity.amenity_name != "" && (
            <div className="rounded-full p-2 flex items-center justify-center" key={index}>
              <img src={amenity.amenity_icon} alt={amenity.amenity_name} title={amenity.amenity_name} className="w-5 h-5 mr-2 rounded-full" />
              <p className="text-xs">{amenity.amenity_name}</p>
            </div>
          )
        ))}
      </div> */}
      </>
      
    );
  };

  const renderService = () => {
    if (!data.service_name) {
      return null;
    }

    return (
      <div className="rounded-md pt-4">
        <p className="text-sm font-semibold mb-2 ">{data.service_name}</p>
        <div className="flex ">
          <p className="text-gray-600 text-sm">Duration <br />{data.service_duration} mins</p>
          <p className="text-gray-600 text-sm ml-8">For <br />{data.service_for}</p>
          <p className="text-gray-600 text-sm ml-8">Starting From<br />₹{data.service_price}</p></div>
      </div>
    );
  };

  const renderDescription = () => {
    const truncateText = (text: string, maxLength: number) => {
      return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    };

    return (
      <div className="mt-2">
        <p className="text-sm text-neutral-500 dark:text-neutral-400">
          {showFullDescription ? description : truncateText(description, 100)}
        </p>
        <button
          className="text-blue-500 text-sm underline mt-1"
          onClick={() => setShowFullDescription(!showFullDescription)}
        >
          {showFullDescription ? "Show less" : "Show more"}
        </button>
      </div>
    );
  };

  const renderContent = () => {

    const truncateAddress = (address: string, maxLength: number) => {
      return address.length > maxLength ? `${address.slice(0, maxLength)}...` : address;
    };

    return (
      <div className="flex-grow p-3 sm:p-5 flex flex-col">
        <a 
          href={makeSEOUrlReturn(title, id, business_type)} 
          onClick={() => makeSEOUrl(title, id, business_type)} 
          className="" 
          key={id} 
          aria-label={`Navigate to ${title} details`} 
          target="_blank" 
          rel="noopener noreferrer"
        >
        <div className="space-y-2">
          <div className="flex items-center justify-between space-x-2">
          <div>
          
          <h2 className="text-lg flex items-center font-medium capitalize">
            
            {title}
            {data.claimed == 1 && (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-7 p-1 text-green-400">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                  </svg>
                )}
            </h2>
            
          </div>
          
   
                
            {
              opening && (
                <div className={`ml-2 px-2 py-1 flex items-center h-6 float-right text-sm text-white rounded-full ${opening == "closed" ? "bg-red-400" : "bg-green-600"}`}>{opening}</div>
              )
            }

          </div>
          <div className="text-sm text-neutral-500 dark:text-neutral-400 overflow-hidden overflow-ellipsis">
            <span>{truncateAddress(address, 30)}</span>
            <span className="line-clamp-1 p-2">
              <Startrating point={Number(data.rating)} reviewCount={Number(data.total_rating)} />
            </span>
          </div>
        </div>
        </a>
        <hr />
        
        {renderDescription()}

        {!isMobile && renderAmenities()}

        {renderService()}
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCardH group relative bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow will-change-transform ${className}`}
      data-nc-id="StayCardH"
    >
      <div className="grid grid-cols-1 md:flex md:flex-row">
        {renderSliderGallery()}
        {renderContent()}
      </div>
    </div>
  );
};

export default StayCardH;
