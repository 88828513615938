import React from "react";
import logo from './logo-red.png';

interface LogoSvgProp {
  alt?: string;
  title?: string;
}

const LogoSvg: React.FC<LogoSvgProp> = ({ alt, title }) => {
  return (
    <div className="logo">
      <img
        className="w-40"
        src={logo}
        alt={alt || 'Feelaxo - Book Appointment for Salons, Spas, and Wellness Centers Online'}
        title={title || 'Feelaxo - Book Appointment for Salons, Spas, and Wellness Centers Online'}
      />
    </div>
  );
};

export default LogoSvg;
