import OpenGraphTag from "components/OpenGraphTag/OpenGraphTag";
import React from "react";
import MyRouter from "routers/index";


function App() {
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      {/* <OpenGraphTag/> */}
      <MyRouter />
    </div>
  );
}

export default App;
