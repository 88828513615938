import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";
import Inspiration from "./Inspiration";
import SalonsAndSpas from "components/FooterCitySelector/SalonsAndSpas";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "1",
    title: "Business Option",
    menus: [
      { href: "https://business.feelaxo.com", label: "For Businesses", title: "List your business on Feelaxo" },
      { href: "/contact-for-demo", label: "Request free demo", title: "Request Free Demo" },
      { href: "https://business.feelaxo.com/about-feelaxo/", label: "About", title: "Feelaxo - About Us" },
      { href: "/TermsCondition", label: "Terms And Conditions", title: "feelaxo - Terms & Conditions" },
      { href: "/privacyPolicy", label: "Privacy Policy", title: "Feelaxo - Privacy Policy" },
      { href: "/faq", label: "FAQ", title: "Feelaxo - Frequently asked questions" },
    ],
  },
  
  {
    id: "2",
    title: "Categories",
    menus: [
      { href: "/listing?query=&type=1", label: "Spa", title: "Top rated Spas near you" },
      { href: "/listing?query=&type=2", label: "Salon", title: "Top rated Salons near you" },
      { href: "/listing?query=&type=3", label: "Wellness", title: "Top rated Wellness Centers near your" },
    ],
  },
  {
    id: "3",
    title: "Connect Us",
    menus: [
      { href: "mailto:pr@feelaxo.com", label: "pr@feelaxo.com", title: "Email us: pr@feelaxo.com" },
      {href:"/contact",label:'Contact', title: "Feelaxo - Contact Us"},
      
    ],
  },
 
 
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">

       
      
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
                title={item.title}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative border-t border-neutral-200 dark:border-neutral-700">

      <div className="bg-gray-100 py-6 mb-8">
          <SalonsAndSpas />
        </div>

      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
        
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo title="Feelaxo - Free salon appointment management system" alt="Feelaxo - Free salon appointment management system" />
          </div>
          <div className="col-span-2 flex items-center md:col-span-3">
            <SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" />
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
      </div>
    </div>
  );
};

export default Footer;