import React, { useState, useEffect, useRef,forwardRef } from "react";
import axios from "axios";
import { GoChevronRight, GoChevronDown, GoChevronLeft } from "react-icons/go";
import {Service, ServiceType } from "../../../types/types";




interface Props {
  business_id: number;
  business_name: string;
  rating: number;
  updateCart: (service: Service) => void;
  removeFromCart: (itemId: number) => void;
  handleAddToCart: (service: Service) => void;
  isServiceInCart: (service: Service) => boolean;
}

const ServiceDetails:React.FC<Props> = ({
  business_id,
  business_name,
  updateCart,
  removeFromCart,
  handleAddToCart,
  isServiceInCart
}) => {
  const [serviceTypeData, setServiceTypeData] = useState<ServiceType[] | null>(null);
  const [activeTab, setActiveTab] = useState<number | null>(null);
  const [expandedServiceId, setExpandedServiceId] = useState<number | null>(null);
  const [expandedDescription, setExpandedDescription] = useState<string | null>(null); // State to hold expanded description
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    axios.get(`/api/services/map/service/${business_id}`)
      .then((response) => {
        setServiceTypeData(response.data);
        setActiveTab(response.data && response.data.length > 0 ? response.data[0].id : null);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [business_id]);

  useEffect(() => {
    // Scroll to the active tab when it's changed
    if (scrollRef.current && activeTab !== null) {
      const activeTabElement = scrollRef.current.querySelector(`[data-tab-id="${activeTab}"]`);
      if (activeTabElement) {
        activeTabElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    }
  }, [activeTab]);

  const scrollRight = () => {
    if (activeTab !== null && serviceTypeData !== null) {
      const index = serviceTypeData.findIndex(type => type.id === activeTab);
      if (index !== -1 && index < serviceTypeData.length - 1) {
        setActiveTab(serviceTypeData[index + 1].id);
      }
    }
  };

  const scrollLeft = () => {
    if (activeTab !== null && serviceTypeData !== null) {
      const index = serviceTypeData.findIndex(type => type.id === activeTab);
      if (index !== -1 && index > 0) {
        setActiveTab(serviceTypeData[index - 1].id);
      }
    }
  };

  const truncateDescription = (description: string) => {
    return description.length > 24 ? `${description.slice(0, 24)}...` : description;
  };

  const toggleDescription = (description: string) => {
    setExpandedDescription(expandedDescription === description ? null : description);
  };


  const CalculateDiscount = (price: number, discount: string, discount_end: string, discount_start: string) => {
    // Check if any of the required parameters are null, undefined, or empty strings
    if (price === null || price === undefined || isNaN(price) ||
        discount === null || discount === undefined || discount.trim() === '' ||
        discount_end === null || discount_end === undefined || discount_end.trim() === '' ||
        discount_start === null || discount_start === undefined || discount_start.trim() === '') {
        return price;
    }

    // Get the current time
    const currentTime = new Date();
    const currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();
    const currentFormattedTime = `${currentHours}:${currentMinutes}`;

    // Parse discount start and end times
    const [startHour, startMinute] = discount_start.split(':').map(Number);
    const [endHour, endMinute] = discount_end.split(':').map(Number);

    // Convert discount start and end times to minutes for easier comparison
    const discountStartMinutes = startHour * 60 + startMinute;
    const discountEndMinutes = endHour * 60 + endMinute;
    const currentMinutesOfDay = currentHours * 60 + currentMinutes;

    // Check if current time falls within the discount window
    if (currentMinutesOfDay >= discountStartMinutes && currentMinutesOfDay <= discountEndMinutes) {
        // Apply discount
        const discountAmount = parseFloat(discount);
        const discountedPrice = price * (1 - discountAmount / 100);
        return discountedPrice;
    } else {
        // No discount applicable
        return price;
    }
}



const CheckDeals = (discount: string, discount_end: string, discount_start: string) => {
  // Check if any of the required parameters are null, undefined, or empty strings
  if (discount === null || discount === undefined || discount.trim() === '' ||
      discount_end === null || discount_end === undefined || discount_end.trim() === '' ||
      discount_start === null || discount_start === undefined || discount_start.trim() === '') {
      return false;
  }

  // Get the current time
  const currentTime = new Date();
  const currentHours = currentTime.getHours();
  const currentMinutes = currentTime.getMinutes();
  const currentFormattedTime = `${currentHours}:${currentMinutes}`;

  // Parse discount start and end times
  const [startHour, startMinute] = discount_start.split(':').map(Number);
  const [endHour, endMinute] = discount_end.split(':').map(Number);

  // Convert discount start and end times to minutes for easier comparison
  const discountStartMinutes = startHour * 60 + startMinute;
  const discountEndMinutes = endHour * 60 + endMinute;
  const currentMinutesOfDay = currentHours * 60 + currentMinutes;

  // Check if current time falls within the discount window
  if (currentMinutesOfDay >= discountStartMinutes && currentMinutesOfDay <= discountEndMinutes) {
      return true;
  } else {
      return false;
  }
}








  return (
    <>
      <div  className="relative mt-6 mb-6">
        <div className="space-y-6">
          <h2 className="text-4xl sm:text-3xl lg:text-3xl font-semibold">Services offered at {business_name}</h2>
          {serviceTypeData != null ? (
            <>
              {serviceTypeData.length !== 0 ? (
                <div className="relative">
                  <div className="flex mb-4 m-4 overflow-x-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100" ref={scrollRef}>
                    <button className=" bg-white drop-shadow-md py-2 px-2 rounded-full inline-flex items-center whitespace-no-wrap absolute top-1 left-[-22px]" onClick={scrollLeft}>
                      <GoChevronLeft  />
                    </button>
                    {serviceTypeData.map((serviceType) => (
                      <button
                        key={serviceType.id}
                        data-tab-id={serviceType.id}
                        className={`mr-4 py-2 px-4 xl:px-5 rounded-full inline-flex items-center whitespace-no-wrap ${activeTab === serviceType.id ? 'bg-black text-white' : 'bg-white font-semibold'}`}
                        onClick={() => setActiveTab(serviceType.id)}
                      >
                        <span className="fixed-line">{serviceType.name}</span>
                      </button>
                    ))}
                    <button className="bg-white drop-shadow-md py-2 px-2 rounded-full inline-flex items-center whitespace-no-wrap absolute top-1 right-[-20px]" onClick={scrollRight}>
                      <GoChevronRight  />
                    </button>
                  </div>
                  {activeTab !== null && serviceTypeData && serviceTypeData.find((type) => type.id === activeTab) && (
                    <>
                      <div className="flex flex-wrap">
                        {serviceTypeData.find((type) => type.id === activeTab)?.services.map((service) => (
                          <div key={service.id} className={`w-full border rounded-xl hover:shadow-lg p-4 mb-4`}>
                            <div onClick={() => setExpandedServiceId(expandedServiceId === service.id ? null : service.id)} className="w-full cursor-pointer flex p-4 bg-white justify-between grid-cols-2 items-center">
                              <div className="md:grid grid-cols-2 w-full">
                                <div>
                                  <h3 className="text-md font-semibold inline-block flex items-center">{service.name} <p className="font-thin inline-block"> &nbsp; ({service.service_for})</p> &nbsp; 
                                    {
                                      CheckDeals(service.discount, service.discount_end, service.discount_start) && (
                                        <div className=" text-sm text-red-400 mr-4 inline-block py-1 px-2 
                                        rounded-full">{service.discount} % OFF</div>
                                      )
                                    }
                                  </h3>
                                </div>
                                <div className="font-light text-green-800 md:items-center md:flex md:justify-end">Starting From ₹ {service.price[0]} </div>
                              </div>
                              <div className="flex w-8">
                                <div><button className="flex items-center">&nbsp;{expandedServiceId === service.id ? (<GoChevronDown />) : (<GoChevronRight />)}</button></div>
                              </div>
                            </div>
                            {expandedServiceId === service.id && (
                              <div className="w-full p-4 border  justify-between rounded-md">
                                
                                {
                                  service.description && (
                                    <p className="font-thin mb-4">{expandedDescription === service.description ? service.description : truncateDescription(service.description)} {service.description.length > 24 && (
                                      <button className="text-blue-500" onClick={() => toggleDescription(service.description)}>
                                        {expandedDescription === service.description ? "Less" : "More"}
                                      </button>
                                    )}</p>
                                  )
                                }

                                <div className="grid items-center grid-cols-3 gap-4 w-full" style={{ gridTemplateColumns: "5fr 5fr 1fr" }}>
                                  <div className="font-light italic">Duration</div>
                                  {
                                          CheckDeals(service.discount, service.discount_end, service.discount_start) ? (
                                            <div className="grid items-center grid-cols-2 gap-2 w-full">
                                              <div className="font-light italic">Actual<br></br> Price</div>
                                              <div className="font-light italic">Discounted<br></br> Price</div>
                                            </div>
                                          ) : (
                                            <>
                                              <div className="font-light italic">Price</div>
                                            </>
                                          )
                                        }
                                  <div></div> {/* Placeholder for button column */}
                                  {service.duration.map((duration, index) => (
                                    <>
                                      <React.Fragment key={index}>
                                        <div className="">{duration} mins</div>
                                        {
                                          service.discount != "" && service.discount ? (
                                            <div className="grid items-center grid-cols-2 gap-4 w-full">
                                                <div className="line-through text-gray-500">₹ {service.price[index]} </div>
                                                <div>₹ {CalculateDiscount(service.price[index], service.discount, service.discount_end, service.discount_start)}</div>
                                            </div>
                                          ) : (
                                            
                                              <div className="">₹ {service.price[index]}</div>
                
                                          )
                                        }
                                        <button
                                          onClick={() => {
                                            if (isServiceInCart({
                                              id: service.id,
                                              name: service.name,
                                              price: CalculateDiscount(service.price[index], service.discount, service.discount_end, service.discount_start).toString(),
                                              discount: service.discount,
                                              service_for: service.service_for,
                                              duration: service.duration[index].toString(),
                                              description: service.description,
                                            })) {
                                              removeFromCart(service.id);
                                            } else {
                                              handleAddToCart({
                                                id: service.id,
                                                name: service.name,
                                                price: CalculateDiscount(service.price[index], service.discount, service.discount_end, service.discount_start).toString(),
                                                discount: service.discount,
                                                service_for: service.service_for,
                                                duration: service.duration[index].toString(),
                                                description: service.description,
                                              });
                                            }
                                          }}
                                          className={`py-2 px-2 xl:px-5 justify-center flex rounded-full hover:text-neutral-900 hover:bg-neutral-100 dark:hover-bg-neutral-800 dark:hover:text-neutral-200  ${isServiceInCart({
                                            id: service.id,
                                            name: service.name,
                                            price: service.price[index].toString(),
                                            discount: service.discount,
                                            service_for: service.service_for,
                                            duration: service.duration[index].toString(),
                                            description: service.description,
                                          }) ? 'bg-gray-100 border border-gray-300 text-red-400' : 'border border-gray-300'}`}
                                        >
                                          {isServiceInCart({
                                            id: service.id,
                                            name: service.name,
                                            price: service.price[index].toString(),
                                            discount: service.discount,
                                            service_for: service.service_for,
                                            duration: service.duration[index].toString(),
                                            description: service.description,
                                          })
                                            ? (
                                              <>
                                                <svg xmlns="http://www.w3.org/2000/svg" className='w-4 h-4 text-red-400' style={{ color: "red" }} viewBox="0 0 384 512"><path style={{ color: "red" }} d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>

                                              </>
                                            )
                                            : (
                                              <>
                                                <svg xmlns="http://www.w3.org/2000/svg" className='w-4 h-4' viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" /></svg>


                                              </>
                                            )
                                          }
                                        </button>

                                      </React.Fragment>
                                    </>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div className=""> Seems that the services have not been added by the business yet </div>
              )}
            </>
          ) : (
            <div className=""> Loading ... </div>
          )}
        </div>
      </div>

    </>
  );
};


export default ServiceDetails;
