import { FC, useEffect, useState } from "react";
import { BusinessData } from "../../types/types";
import GallerySlider from "components/GallerySlider/GallerySlider";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import StartRating from "components/StartRating/StartRating";
import Heading from "components/Heading/Heading";
import Loading from "./feelaxo-loading.gif";

interface FrontendData {
  city_id: number;
  city_name: string;
}

interface MenuItem {
  id: number;
  name: string;
}


const SectionGridFeaturePlaces: FC = () => {
  const [businesses, setBusinesses] = useState<BusinessData[]>([]);
  const [noDataToShow, setNoDataToShow] = useState(false);
  const [City, setCity] = useState<FrontendData | null>(null);
  const [loading, setLoading] = useState(true);
  const [activeItem, setActiveItem] = useState<number>(2);
  const [canonicalUrl, setCanonicalUrl] = useState<string>('');

  const menuItems: MenuItem[] = [
    { id: 2, name: "SALON" },
    { id: 1, name: "SPA" },
    { id: 3, name: "WELLNESS" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const cookieValue = document.cookie;
      const match = cookieValue.match(/frontendData=([^;]*)/);

      if (!match) {
        console.error("Error: frontendData cookie not found");
        setLoading(false);
        return;
      }

      try {
        const decodedValue = decodeURIComponent(match[1]);
        const { city_id }: FrontendData = JSON.parse(decodedValue);

        if (!city_id || isNaN(city_id)) {
          console.error("Error: Invalid or missing city_id in frontendData");
          setLoading(false);
          return;
        }

        const response = await fetch("/api/business/post/city", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ city_id }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        if (data.error) {
          console.error("Error from backend:", data.error);
          setNoDataToShow(true);
        } else {
          setBusinesses(data);
        }
      } catch (error) {
        console.error("Error in fetch or parsing JSON:", error);
      } finally {
        setLoading(false);
      }
    };

    const checkForCookie = () => {
      const cookieValue = document.cookie;
      const match = cookieValue.match(/frontendData=([^;]*)/);

      if (match) {
        try {
          const decodedValue = decodeURIComponent(match[1]);
          const cityData: FrontendData = JSON.parse(decodedValue);
          setCity(cityData);
          setNoDataToShow(false);
          fetchData();
        } catch (error) {
          console.error("Error parsing city data:", error);
        }
      } else {
        setTimeout(checkForCookie, 1000);
      }
    };
    checkForCookie();
  }, []);


  function shortenAddress(address: string) {
    if (address.length > 12) {
      address = address.split(',')[0].slice(0, 12);
    }
    return address;
  }

  const filteredBusinesses = activeItem !== null ? businesses.filter(business => business.business_type === activeItem) : businesses;

  const makeSEOUrl = (businessName: string, businessId: number, businessType: number) => {
    let url = `/venue/`;
    let bType: string;

    if (businessType === 1) {
        bType = "spa";
    } else if (businessType === 2) {
        bType = "salon";
    } else if (businessType === 3) {
        bType = "wellness";
    } else {
        throw new Error("Invalid business type");
    }

    const sanitizedCityName = City?.city_name.replace(/\s/g, "-");
    const sanitizedBusinessName = businessName.replace(/\s/g, "-");

    url = `${url}${bType}/${sanitizedCityName}/${sanitizedBusinessName}?id=${businessId}`;

    return url;


}


  return (
    <div>
      {loading ? (

           <div className="w-12 h-12 mx-auto flex justify-center items-center">
          <img src={Loading} alt="Loading..." />

        </div>
      ) : noDataToShow ? (
        <div>No data to show</div>
      ) : (
        <div className="nc-SectionGridFeaturePlaces relative">
          <h2 >Top rated venues in {City?.city_name}</h2>
          <div className="mb-8 lg:mb-11 flex justify-start ">
            
            <div className="flex items-center justify-between p-2">
              <nav className="nc-Nav relative flex w-full overflow-x-auto text-sm md:text-base hiddenScrollbar" data-nc-id="Nav">
                <ul className="flex  sm:space-x-2">
                  {menuItems.map((item, index) => (
                    <li key={item.id} className="nc-NavItem relative" data-nc-id="NavItem">
                      <button
                        className={`block !leading-none font-medium whitespace-nowrap px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${(activeItem === null && index === 0) || activeItem === item.id ? "bg-secondary-800 text-secondary-50" : ""}`}
                        onClick={() => setActiveItem(item.id)}
                      >
                        {item.name}
                      </button>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
          <div className="grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {filteredBusinesses.length === 0 ? (
              <>
                {activeItem !== null && (
                  <div className="text-center w-full">No venues to display for {menuItems.find(item => item.id === activeItem)?.name}</div>
                )}
              </>
            ) : (
              filteredBusinesses.slice(0, 8).map((business) => (
                <div className="nc-StayCard group hover:shadow-lg relative bg-white dark:bg-neutral-900 border border-neutral-300 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform" key={business.id}>
                  <div className="relative w-full">
                  
                    <GallerySlider business_city={City?.city_name || ""} business_address={business.address} business_name={business.business_name} uniqueID={`StayCard_${business.id}`} ratioClass="aspect-w-4 aspect-h-3" galleryImgs={business.gallery} business_id={business.id} business_type={business.business_type} href={makeSEOUrl(business.business_name, business.id, business.business_type)}  />
                    
                  </div>
                  <a href={makeSEOUrl(business.business_name, business.id, business.business_type)} target="_blank" className="block">
                    <div className="p-4 space-y-4">
                      <div className="space-y-2">
                        <span className="text-sm text-neutral-500 dark:text-neutral-400"></span>
                        <div className="flex items-center space-x-2">
                          <h2 className="font-medium capitalize text-lg">
                            <span className="line-clamp-1">{business.business_name}</span>
                          </h2>
                        </div>
                        <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                          </svg>
                          <span className="">{`${shortenAddress(business.address)}, ${City?.city_name}`}</span>
                        </div>
                      </div>
                      <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
                      <div className="flex justify-between items-center">
                        {business.status === "open" ? (
                          <div className="open-status bg-green-100 text-green-600 px-4 py-1 rounded-full">
                            <p>Open</p>
                          </div>
                        ) : (
                          <div className="closed-status bg-red-100 text-red-600  px-4 py-1 rounded-full">
                            <p>Closed</p>
                          </div>
                        )}
                        <StartRating point={business.rating} reviewCount={business.total_rating} />
                      </div>
                    </div>
                  </a>
                </div>
              ))
            )}
          </div>
          <div className="flex mt-16 justify-center items-center">
            <ButtonPrimary href={`/listing?query=&type=1`} loading={false}>Show more</ButtonPrimary>
          </div>
        </div>
      )}
    </div>
  );
}

export default SectionGridFeaturePlaces;